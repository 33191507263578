import React from 'react';


import isMobile from './isMobile';

const styles = require('./prevNext-styles.module.css');

const PrevNext = props => {
  const className = isMobile.iOS() ? styles.hidden : styles.navlinks;

  return (
    <div className={className} style={{ color: props.color }}>
      {props.prev && (
        <a className={styles.prev} href={props.prevHref} {...props}>
          {props.prev}
        </a>
      )}
      {props.next && (
        <a className={styles.next} href={props.nextHref} {...props}>
          {props.next}
        </a>
      )}
    </div>
  );
};

export default PrevNext;
