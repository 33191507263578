import React from 'react';


const styles = require('./Wrap-styles.module.css');

const Wrap = props => (
  <section className={styles[props.styleHint] || styles.wrap} id={props.id}>
    {props.children}
  </section>
);

export default Wrap;
