import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import PrevNext from '../components/prevNext';
import Link from '../components/Link';

const styles = require('./Footer-styles.module.css');

const detailsQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        title
        company
        menuLinks {
          name
          link
        }
      }
    }
    file: file(relativePath: { eq: "images/icon.png" }) {
      childImageSharp {
        fixed(width: 128) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => (
        <footer className={styles.footer} id="footer">
          <PrevNext prev="Back up" prevHref="#header" />
          <p data-aos="fade-right" data-aos-offset="0" data-aos-delay="0" data-aos-duration="450">
            <Img fixed={data.file.childImageSharp.fixed} />
            <br />
            &copy; 2020 {data.site.siteMetadata.company}
            <br />
            <small>
              <strong>KvK</strong> 74263404 | <strong>BTW</strong> NL859829765B01 | <strong>IBAN</strong> NL84 KNAB 0258
              6740 16
              <br />
              <a href="/terms">Terms of service</a> <a href="/privacy">Privacy</a> <a href="/disclaimer">Disclaimer</a>{' '}
            </small>
          </p>

          <ul data-aos="fade-left" data-aos-offset="0" data-aos-delay="200" data-aos-duration="450">
            {data.site.siteMetadata.menuLinks.map(link => (
              <li key={link.name}>
                <Link to={link.link}>{link.name}</Link>
              </li>
            ))}
          </ul>

        </footer>
      )}
    />
  );
};

export default Footer;
