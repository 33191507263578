const isMobile =
  typeof window !== 'undefined'
    ? {
        Android() {
          return !!navigator.userAgent.match(/Android/i);
        },
        iOS() {
          return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera() {
          return !!navigator.userAgent.match(/Opera Mini/i);
        },
        Windows() {
          return !!navigator.userAgent.match(/IEMobile/i) || !!navigator.userAgent.match(/WPDesktop/i);
        },
        any() {
          return isMobile.Android() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
        },
      }
    : {
        Android() {
          return false;
        },
        iOS() {
          return false;
        },
        Opera() {
          return false;
        },
        Windows() {
          return false;
        },
        any() {
          return false;
        },
      };

export default isMobile;
